<template>
  <div>
    <validation-observer
      ref="serviceRequestFormSave"
    >
      <b-form @submit.prevent="submitSaveForm">
        <b-row
          class="content-header v-sticky-sidebar-container service_request_task collapse__form-wrapper"
          :class="{ 'white-background': !editFormInOtherStatus, 'green-background': editFormInOtherStatus }"
          style="border-radius: 6px;"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left my-1 top-info-right"
            cols="12"
            md="3"
          >
            <div
              class="user_block ac_type"
              style="float: none;"
            >
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="">
                  {{ requestForm.status }}
                </h5>
              </div>
            </div>

            <b-card
              v-if="editFormInOtherStatus"
              class="mt-2 service-forms-card success-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">you're now</span>
                  Editing this form
                </h3>
              </template>
              <b-card-text
                class="signing-stage__title"
              >
                <span>After submitting the signed form, the system will generate a signed PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>

            <app-collapse
              v-if="requestForm.status == 'To prepare' || editFormInOtherStatus"
              accordion
              class="mt-2 service-form-collapse"
            >
              <app-collapse-item
                ref="purchaserDetailsRef"
                :is-visible="purchaserDetailsCollapse"
                title="Purchaser/Next of Kin Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Purchaser/Next of Kin Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">Purchaser Name 购买者</span>
                    </div>
                    <b-form-group
                      v-if="currentCustomerNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Purchaser Name"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="customerName"
                          v-model="customerName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                        border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': customerNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeCustomerNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="customerNameImage"
                        v-b-modal.customer-name-modal
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">Next-of-Kin 购买者至亲</span>
                    </div>
                    <b-form-group
                      v-if="currentKinNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Next-of-Kin"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="kinName"
                          v-model="kinName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                        border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': kinNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeKinNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="kinNameImage"
                        v-b-modal.kin-name-modal
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentKinNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Contact No. 联络电话"
                      label-class="field-label label-required-star"
                      label-for="contactNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Contact No."
                        vid="contactNo"
                        rules="required"
                      >
                        <b-form-input
                          id="contactNo"
                          ref="contactNo"
                          v-model="contactNo"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Address 联络地址"
                      label-for="address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address"
                        vid="address"
                        rules="required"
                      >
                        <b-form-textarea
                          id="address"
                          ref="address"
                          v-model="address"
                          rows="3"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="File No. 文件号码"
                      label-class="field-label label-required-star"
                      label-for="fileNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File No."
                        vid="fileNo"
                        rules="required"
                      >
                        <b-form-input
                          id="fileNo"
                          ref="fileNo"
                          v-model="fileNo"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="feedbackDetailsRef"
                title="Feedbacks"
                class="custom-collapse-icon"
                :is-visible="feedbackCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Feedbacks</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <div class="p-1 mt-50">
                    <span class="text-bold-black">(A) Service 在服务态度方面</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Staff’s Attitude 人员服务态度"
                      label-for="staffAttitude"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Staff’s Attitude"
                        vid="staffAttitude"
                        rules="required"
                      >
                        <b-form-radio-group
                          id="staffAttitude"
                          ref="staffAttitude"
                          v-model="staffAttitude"
                          stacked
                          name="staffAttitude"
                          class="form-custom-input-checkbox"
                          :options="ratingOptions"
                          @input="staffAttitudeVal = [staffAttitude]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Courtesy 礼貌"
                      label-for="courtesy"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Courtesy"
                        vid="courtesy"
                        rules="required"
                      >
                        <b-form-radio-group
                          id="courtesy"
                          ref="courtesy"
                          v-model="courtesy"
                          stacked
                          name="courtesy"
                          class="form-custom-input-checkbox"
                          :options="ratingOptions"
                          @input="courtesyVal = [courtesy]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Effectiveness 效率"
                      label-for="effectiveness"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Effectiveness"
                        vid="effectiveness"
                        rules="required"
                      >
                        <b-form-radio-group
                          id="effectiveness"
                          ref="effectiveness"
                          v-model="effectiveness"
                          stacked
                          name="effectiveness"
                          class="form-custom-input-checkbox"
                          :options="ratingOptions"
                          @input="effectivenessVal = [effectiveness]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="text-bold-black label-required-star">(B) Is our company providing professional way of service? 是否具备殡葬的专业知识？</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="professionalService"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Professional Service"
                        vid="professionalService"
                        rules="required"
                      >
                        <b-form-radio-group
                          id="professionalService"
                          ref="professionalService"
                          v-model="professionalService"
                          stacked
                          name="professionalService"
                          class="form-custom-input-checkbox"
                          :options="psOptions"
                          @input="professionalServiceVal = [professionalService]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <div class="p-1 mt-50">
                    <span class="text-bold-black label-required-star">(C) Suggestion or improvement 建议加强事项</span>
                  </div>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="suggestion"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="suggestion"
                        vid="suggestion"
                        rules="required"
                      >
                        <b-form-textarea
                          id="suggestion"
                          ref="suggestion"
                          v-model="suggestion"
                          rows="3"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
            <div v-if="!editFormInOtherStatus">
              <b-card
                v-if="requestForm.status == 'Ready to sign'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Ready to Sign?
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
                </b-card-text>

                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  @click="startSignProcess"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Sign now</span>
                </b-button>
              </b-card>
              <b-card
                v-if="requestForm.status == 'Signing'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Start signing
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 1 signature from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    v-if="isPurchaserSign"
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
                </b-card-text>
              </b-card>

              <b-card
                v-if="requestForm.status == 'Signed and Sent'"
                class="mt-2 service-forms-card"
              >
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 1 signature from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click="resendSignedFormModal"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span>Resend signed form</span>
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
            <div class="d-form__action-btn">
              <b-button
                variant="outline-primary"
                class="mt-2"
                style="background: #FFF;"
                :to="{ name: 'operation-service-requests-show', params: { id: serviceRequest._id } }"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                  size="16"
                />
                <span>Return to {{ serviceRequest.stringID }}</span>
              </b-button>
              <b-button
                v-if="!editFormInOtherStatus && requestForm.status != 'To prepare'"
                variant="outline-primary"
                class="mt-2 ml-auto"
                style="background: #FFF;"
                @click="editForm"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-col>
          <b-col
            class="content-header-right my-1 d-md-block"
            style="position: relative;"
            md="9"
            cols="12"
          >
            <!-- Table Container Card -->
            <b-card
              no-body
              class="mb-0"
            >
              <div class="orn-form-wrapper d-form_wrapper__adjust-margin">
                <div
                  class="mb-1 d-flex justify-content-between"
                >
                  <div class="d-flex">
                    <div
                      class="d-form-logo_section"
                    />
                    <div class="d-form-company-section-tag">
                      <h2 class="c-title-top">
                        富貴山莊
                      </h2>
                      <div class="d-flex">
                        <h2 class="c-title-bottom">
                          Mount Prajna Ltd.
                        </h2>
                        <div class="c-info-des">
                          <p>
                            (Co. Reg. No. 200401183W)
                          </p>
                          <p>
                            (GST Group Reg. No. M90364395L)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-form-no_section">
                    <h5>
                      {{ digitalForm.formNumber }}
                    </h5>
                    <h5>
                      {{ digitalForm.revision }}
                    </h5>
                  </div>
                </div>
                <div class="urn-repository-order_section">
                  <div class="b-form-section-title my-2">
                    <h3>
                      Customer’s comment form <span>客户意见表</span>
                    </h3>
                  </div>
                  <p class="d-form-warning-text">
                    We believe that the continuous and successful way to improve company’s quality service is to have feedback from our clients and members of the public.
                  </p>
                  <p class="d-form-warning-text">
                    我们深信唯有不断的追求进步，才能提升我们的服务品质。请让我们得到您对本公司的意见如何？
                  </p>
                  <p class="d-form-warning-text">
                    Kindly let us know your views and recommendation as to our service.
                  </p>
                </div>
                <div class="urn-form-particular_section">
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label-cols="12"
                        label-cols-md="2"
                        label-cols-lg="2"
                        label-class="label-width-180"
                        label="Purchaser 购买者*:"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          v-if="currentCustomerNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerName', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': customerNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="customerNameImage"
                            :src="customerNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label-cols="12"
                        label-cols-md="2"
                        label-cols-lg="2"
                        label-class="label-width-180"
                        label="Next-of-Kin 购买者至亲:"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          v-if="currentKinNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinName', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': kinNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="kinNameImage"
                            :src="kinNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label-cols="12"
                        label-cols-md="2"
                        label-cols-lg="2"
                        label-class="label-width-180"
                        label="Contact No. 联络电话*: "
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || contactNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('contactNo', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !contactNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ contactNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label-cols="12"
                        label-cols-md="4"
                        label-cols-lg="2"
                        label-class="label-width-180"
                        label="Address 联络地址*: "
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || address), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('address', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !address"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ address || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label-cols="12"
                        label-cols-md="2"
                        label-cols-lg="2"
                        label-class="label-width-180"
                        label="File No. 档案编号*: "
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || fileNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('fileNo', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fileNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ fileNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="d-form-an-ling-block">
                    <b-table-simple
                      class="d-form-customer-feedback-table"
                      borderless
                      responsive
                    >
                      <b-tbody>
                        <b-tr>
                          <b-th style="padding-left: 21px">
                            (A) Service 在服务态度方面
                          </b-th>
                          <b-th class="text-center">
                            Excellent 良好
                          </b-th>
                          <b-th class="text-center">
                            Good 好
                          </b-th>
                          <b-th class="text-center">
                            Bad 差
                          </b-th>
                        </b-tr>
                        <b-tr>
                          <b-td style="padding-right: 14px !important;">
                            <span style="padding-right: 12px;">1</span>Staff’s Attitude 人员服务态度*</b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': staffAttitude, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="staffAttitude == 'Excellent 良好'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': staffAttitude, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="staffAttitude == 'Good 好'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': staffAttitude, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="staffAttitude == 'Bad 差'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><span style="padding-right: 12px;">2</span>Courtesy 礼貌*</b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': courtesy, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="courtesy == 'Excellent 良好'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': courtesy, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="courtesy == 'Good 好'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': courtesy, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="courtesy == 'Bad 差'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><span style="padding-right: 12px;">3</span>Effectiveness 效率*</b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': effectiveness, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="effectiveness == 'Excellent 良好'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': effectiveness, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="effectiveness == 'Good 好'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                          <b-td
                            class="text-center customer-feedback-custom-td"
                            :class="{ 'd-form-table-td-blank-bg': requestForm.status == 'To prepare', 'value-filled': effectiveness, 'edit-mode': editFormInOtherStatus }"
                          >
                            <feather-icon
                              v-if="effectiveness == 'Bad 差'"
                              icon="CheckIcon"
                              class=""
                              size="24"
                            />
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div class="d-form-an-ling-block mt-2">
                    <h3 class="pl-2 small-d__padding-left-0">(B) Is our company providing professional way of service?*
                      <br>
                      <span style="padding-left: 1.8rem;">是否具备殡葬的专业知识？</span>
                    </h3>
                    <div
                      class="mt-1 pl-2-ccf"
                    >
                      <b-form-checkbox-group
                        v-model="professionalServiceVal"
                        :options="psOptions"
                        name="professionalService"
                        class="form-custom-input-checkbox"
                        style="pointer-events: none; padding-left: 1.8rem;"
                      />
                    </div>
                  </div>
                  <div class="d-form-an-ling-block mt-2">
                    <h3 class="pl-2 small-d__padding-left-0">(C) Suggestion or improvement*
                      <br>
                      <span style="padding-left: 1.8rem;">建议加强事项</span>
                    </h3>
                    <div
                      class="mt-1 pl-2-ccf"
                    >
                      <div
                        class="empty-input__text"
                        style="margin-left: 1.8rem; min-height: 100px;"
                        :class="{ 'bg-white': (requestForm.status != 'To prepare' || suggestion), 'edit-mode': editFormInOtherStatus }"
                        @click="handleClick('suggestion', 'feedbackDetailsRef')"
                      >
                        <p
                          v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !suggestion"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ suggestion || '-' }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="my-2">
                    <p class="d-form-warning-text">
                      Thank you for your feedback and we would like to take this opportunity to salute you for your kindly support. Please submit this questionnaire’s to our service department staff.
                    </p>
                    <p class="d-form-warning-text">
                      感谢您拨出宝贵的时间填写此问卷, 富贵山庄衷心感谢您的支持与爱护。填毕此问卷后, 请将此问卷 交回给我们的服务人员。
                    </p>
                  </div>
                  <div class="mt-5">
                    <b-row>
                      <b-col md="6">
                        <div
                          class="d-form-company-contact-info"
                        >
                          <h6>
                            Tel/Hotline 电话/热线:
                          </h6>
                          <p>(65) 6397 2272</p>
                        </div>
                        <div
                          class="d-form-company-contact-info"
                        >
                          <h6>
                            Fax 传真:
                          </h6>
                          <p>(65) 6795 3070</p>
                        </div>
                        <div class="d-form-company-name">
                          <h3>Nirvana Memorial Garden Pte Ltd
                            <br>
                            <span>富贵山庄敬上</span>
                          </h3>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <p class="d-form-signature-title">
                          Customer Signature 顾客签名
                        </p>
                        <div
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.purchaser-sign-modal
                          class="form-signature-box"
                          :class="{ 'signed': isPurchaserSign, 'edit-mode': editFormInOtherStatus }"
                          :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                        >
                          <div class="form-signature-content">
                            <span
                              v-if="!isPurchaserSign"
                              class="form-signature-text"
                            >
                              Sign here
                            </span>
                            <b-img
                              v-else
                              :src="purchaserSign"
                              alt="authorized-sign"
                            />
                          </div>
                        </div>
                        <div class="signature-date-block">
                          <p>Name 姓名: {{ customerName }}</p>
                          <p v-if="purchaserSignDate">
                            Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                          </p>
                          <p v-else>
                            Date 日期:
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="pt-1">
                      <b-col md="6">
                        <div class="d-form-company-address">
                          <p class="d-form-warning-text">
                            950 Old Choa Chua Kang Road, Singapore 699816
                          </p>
                          <h5>www.nirvana.com.sg</h5>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div class="d-flex justify-content-between">
                          <p class="d-form-warning-text">
                            Tel: (65) 63972272
                          </p>
                          <p class="d-form-warning-text">
                            Fax: (65) 63972252
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <div
          v-if="requestForm.status == 'To prepare'"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Customer Comment Form</span>
          </p>
          <b-nav
            :class="{'d-form_action-btn_tablet': isMobile()}"
            class="padding-left-right-20"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Customer Comment Form</span>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-btn__mob-li': isMobile(), 'ml-auto margin-right-5': !isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                @click="submitSaveForm('saveOnly')"
              >
                Save changes
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-second_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                @click="makeFormNotRequired"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Not Required</span>
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-third_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                @click="submitSaveForm('readyToSign')"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Ready to Sign</span>
              </b-button>
            </li>
          </b-nav>
        </div>

        <div
          v-if="editFormInOtherStatus"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Editing Customer Comment Form</span>
          </p>
          <b-nav
            class="padding-left-right-20"
            :class="{'d-form_action-btn_tablet': isMobile()}"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Editing Customer Comment Form</span>
            </li>
            <li
              :class="{'ml-auto margin-right-5': !isMobile(), 'mob__cancel-btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                class="action-cancel_btn"
                @click="editFormInOtherStatus = false; makeFormValues()"
              >
                Cancel Editing
              </b-button>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-editing__btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                class="action-done_btn"
                @click="submitSaveFormConfirmation()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Done</span>
              </b-button>
            </li>
          </b-nav>
        </div>
      </b-form>
    </validation-observer>
    <!-- authorized signature modal -->
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Customer Signature & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- kin name -->
    <!-- submit signed form -->
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitSaveForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BNav, BForm, BFormTextarea, BFormCheckboxGroup,
  BFormRadioGroup, BTab, BTabs, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'
// eslint-disable-next-line
  import 'quill/dist/quill.core.css'
// eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BNav,
    BForm,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BTab,
    BTabs,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,

    quillEditor,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      psOptions: [
        { text: 'Yes 是', value: 'Yes 是' },
        { text: 'No 否', value: 'No 否' },
        { text: 'Need to improve 须多加强', value: 'Need to improve 须多加强' },
      ],
      ratingOptions: [
        { text: 'Excellent 良好', value: 'Excellent 良好' },
        { text: 'Good 好', value: 'Good 好' },
        { text: 'Bad 差', value: 'Bad 差' },
      ],
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      customerNameImageError: false,
      kinNameImageError: false,
      purchaserDetailsCollapse: true,
      feedbackCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      contactNo: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      fileNo: '',
      address: '',
      staffAttitude: '',
      staffAttitudeVal: [],
      courtesy: '',
      courtesyVal: '',
      effectiveness: '',
      effectivenessVal: [],
      professionalService: '',
      professionalServiceVal: [],
      suggestion: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingPurchaserSignObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      required,
    }
  },
  created() {
    this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    makeFormValues() {
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.address = this.serviceRequest.formValues.find(o => o.key === 'customerAddress')?.value
      this.contactNo = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.fileNo = this.serviceRequest.fileNo
      if (this.requestForm.values.length) {
        const staffAttitudeObject = this.requestForm.values.find(o => o.key === 'staffAttitude')
        if (staffAttitudeObject) {
          this.staffAttitude = staffAttitudeObject.value
          this.staffAttitudeVal = [staffAttitudeObject.value]
        }
        const courtesyObject = this.requestForm.values.find(o => o.key === 'courtesy')
        if (courtesyObject) {
          this.courtesy = courtesyObject.value
          this.courtesyVal = [courtesyObject.value]
        }
        const effectivenessObject = this.requestForm.values.find(o => o.key === 'effectiveness')
        if (effectivenessObject) {
          this.effectiveness = effectivenessObject.value
          this.effectivenessVal = [effectivenessObject.value]
        }
        const professionalServiceObject = this.requestForm.values.find(o => o.key === 'professionalService')
        if (professionalServiceObject) {
          this.professionalService = professionalServiceObject.value
          this.professionalServiceVal = [professionalServiceObject.value]
        }
        const suggestionObject = this.requestForm.values.find(o => o.key === 'suggestion')
        if (suggestionObject) {
          this.suggestion = suggestionObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        const purchaserSignObj = {
          key: 'purchaserSign',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSign = data
          this.isPurchaserSign = true
          purchaserSignObj.date = new Date()
          this.existingPurchaserSignObject = purchaserSignObj
        } else {
          const formData = new FormData()
          const allSigned = true

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.requestForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    async handleClick(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].focus()
      }
    },
    async handleClickDateField(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].fp.open()
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.currentCustomerNameType = type
            }
          })
      } else {
        this.currentCustomerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    submitSaveFormConfirmation() {
      if (this.requestForm.status === 'Signed and Sent') {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitSaveForm()
      }
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
      // this.editFormInOtherStatus = false
      // this.makeFormValues()
    },
    submitSaveForm(option = null, submit = false) {
      this.$refs.serviceRequestFormSave.validate().then(success => {
        let hasCustomerImage = true
        if (this.currentCustomerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        if ((option && option === 'saveOnly') || (success && hasCustomerImage)) {
          const formData = new FormData()
          if (option && option === 'readyToSign') {
            formData.append('readyToSign', true)
          }
          const valueArray = []
          let srValueArray = this.serviceRequest.formValues
          const sfValueObject = {}
          const srValueObject = {}
          const customerNameObj = {
            key: 'customerName',
          }
          if (this.currentCustomerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNameObj.key).concat(customerNameObj)

          const contactNoObj = {
            key: 'customerContact',
            value: this.contactNo,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== contactNoObj.key).concat(contactNoObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.currentKinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNameObj.key).concat(kinNameObj)

          const addressObj = {
            key: 'customerAddress',
            value: this.address,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== addressObj.key).concat(addressObj)

          srValueObject.fileNo = this.fileNo

          const staffAttitudeObj = {
            key: 'staffAttitude',
            value: this.staffAttitude,
          }
          valueArray.push(staffAttitudeObj)

          const courtesyObj = {
            key: 'courtesy',
            value: this.courtesy,
          }
          valueArray.push(courtesyObj)

          const effectivenessObj = {
            key: 'effectiveness',
            value: this.effectiveness,
          }
          valueArray.push(effectivenessObj)

          const professionalServiceObj = {
            key: 'professionalService',
            value: this.professionalService,
          }
          valueArray.push(professionalServiceObj)

          const suggestionObj = {
            key: 'suggestion',
            value: this.suggestion,
          }
          valueArray.push(suggestionObj)

          let purchaserSignVal = 0
          if (this.existingPurchaserSignObject.key) {
            purchaserSignVal = 1
            valueArray.push(this.existingPurchaserSignObject)
          }

          let allSigned = false
          if (purchaserSignVal) {
            allSigned = true
          }

          // let valueChanged = false
          // if (this.requestForm.status === 'Signed and Sent') {
          //   const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
          //   if (difference.length) {
          //     valueChanged = true
          //   }
          // }

          formData.append('values', JSON.stringify(valueArray))
          formData.append('srValues', JSON.stringify(srValueArray))
          formData.append('sfValues', JSON.stringify(sfValueObject))
          formData.append('srValueObject', JSON.stringify(srValueObject))
          formData.append('allSigned', allSigned)
          formData.append('currentStatus', this.requestForm.status)
          formData.append('formName', this.requestForm.formID.name)
          formData.append('serviceRequest', this.serviceRequest._id)
          formData.append('serviceForm', this.serviceRequest.serviceForm._id)
          formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                await this.$emit('update:serviceRequest', response.data.sr)
                this.makeFormValues()
                this.editFormInOtherStatus = false
                if (option && option === 'edited' && response.data.data.status === 'Signed and Sent' && submit) {
                  this.submitSignedForm('edited')
                } else if (response.data.data.status === 'Signing' && allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Form Data Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
