<template>
  <div>
    <validation-observer
      ref="serviceRequestFormSave"
    >
      <b-form @submit.prevent="submitSaveForm">
        <b-row
          class="content-header v-sticky-sidebar-container service_request_task collapse__form-wrapper"
          :class="{ 'white-background': !editFormInOtherStatus, 'green-background': editFormInOtherStatus }"
          style="border-radius: 6px;"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left my-1 top-info-right"
            cols="12"
            md="3"
          >
            <div
              class="user_block ac_type"
              style="float: none;"
            >
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="">
                  {{ requestForm.status }}
                </h5>
              </div>
            </div>

            <b-card
              v-if="editFormInOtherStatus"
              class="mt-2 service-forms-card success-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">you're now</span>
                  Editing this form
                </h3>
              </template>
              <b-card-text
                class="signing-stage__title"
              >
                <span>After submitting the signed form, the system will generate a signed PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>

            <app-collapse
              v-if="requestForm.status == 'To prepare' || editFormInOtherStatus"
              accordion
              class="mt-2 service-form-collapse"
            >
              <app-collapse-item
                ref="purchaserDetailsRef"
                :is-visible="purchaserDetailsCollapse"
                title="Purchaser Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Purchaser Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span
                        class="field-label label-required-star"
                      >I as Purchaser 我</span>
                    </div>
                    <b-form-group
                      v-if="currentCustomerNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="I as Purchaser"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="customerName"
                          v-model="customerName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                        border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': customerNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeCustomerNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="customerNameImage"
                        v-b-modal.customer-name-modal
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="NRIC 身份证号码"
                      label-for="customerNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="customerNRIC"
                        rules="required"
                      >
                        <b-form-input
                          id="customerNRIC"
                          ref="customerNRIC"
                          v-model="customerNRIC"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="kinDetailsRef"
                :is-visible="kinDetailsCollapse"
                title="Next of Kin Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Next of Kin Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">I as Next of Kin 我</span>
                    </div>
                    <b-form-group
                      v-if="currentKinNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="I as Next of Kin"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="kinName"
                          v-model="kinName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                        border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': kinNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeKinNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="kinNameImage"
                        v-b-modal.kin-name-modal
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentKinNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="NRIC 身份证号码"
                      label-for="kinNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="kinNRIC"
                        rules=""
                      >
                        <b-form-input
                          id="kinNRIC"
                          ref="kinNRIC"
                          v-model="kinNRIC"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="particularDetailsRef"
                title="Pedestal Installation Details"
                class="custom-collapse-icon"
                :is-visible="particularCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Pedestal Installation Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="File No. 文件号码"
                      label-for="fileNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File No."
                        vid="fileNo"
                        rules="required"
                      >
                        <b-form-input
                          id="fileNo"
                          ref="fileNo"
                          v-model="fileNo"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Pedestal No. 神主牌位"
                      label-for="pedestalNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Pedestal No."
                        vid="pedestalNo"
                        rules=""
                      >
                        <b-form-input
                          id="pedestalNo"
                          ref="pedestalNo"
                          v-model="pedestalNo"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="text-bold-black">1)</span>
                    </div>
                    <b-form-group
                      label-class="field-label"
                      label="Name of Beneficiary 受供奉者姓氏"
                      label-for="firstBeneficiary"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="firstBeneficiary"
                        vid="firstBeneficiary"
                        rules=""
                      >
                        <b-form-input
                          id="firstBeneficiary"
                          ref="firstBeneficiary"
                          v-model="firstBeneficiary"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Relationship 关系"
                      label-for="firstRelationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="firstRelationship"
                        vid="firstRelationship"
                        rules=""
                      >
                        <b-form-input
                          id="firstRelationship"
                          ref="firstRelationship"
                          v-model="firstRelationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="text-bold-black">2)</span>
                    </div>
                    <b-form-group
                      label-class="field-label"
                      label="Name of Beneficiary 受供奉者姓氏"
                      label-for="secondBeneficiary"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="secondBeneficiary"
                        vid="secondBeneficiary"
                        rules=""
                      >
                        <b-form-input
                          id="secondBeneficiary"
                          ref="secondBeneficiary"
                          v-model="secondBeneficiary"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Relationship 关系"
                      label-for="secondRelationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="secondRelationship"
                        vid="secondRelationship"
                        rules=""
                      >
                        <b-form-input
                          id="secondRelationship"
                          ref="secondRelationship"
                          v-model="secondRelationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="text-bold-black">3)</span>
                    </div>
                    <b-form-group
                      label-class="field-label"
                      label="Name of Beneficiary 受供奉者姓氏"
                      label-for="thirdBeneficiary"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="thirdBeneficiary"
                        vid="thirdBeneficiary"
                        rules=""
                      >
                        <b-form-input
                          id="thirdBeneficiary"
                          ref="thirdBeneficiary"
                          v-model="thirdBeneficiary"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Relationship 关系"
                      label-for="thirdRelationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="thirdRelationship"
                        vid="thirdRelationship"
                        rules=""
                      >
                        <b-form-input
                          id="thirdRelationship"
                          ref="thirdRelationship"
                          v-model="thirdRelationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="text-bold-black">4)</span>
                    </div>
                    <b-form-group
                      label-class="field-label"
                      label="Name of Beneficiary 受供奉者姓氏"
                      label-for="fourthBeneficiary"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="fourthBeneficiary"
                        vid="fourthBeneficiary"
                        rules=""
                      >
                        <b-form-input
                          id="fourthBeneficiary"
                          ref="fourthBeneficiary"
                          v-model="fourthBeneficiary"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Relationship 关系"
                      label-for="fourthRelationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="fourthRelationship"
                        vid="fourthRelationship"
                        rules=""
                      >
                        <b-form-input
                          id="fourthRelationship"
                          ref="fourthRelationship"
                          v-model="fourthRelationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="text-bold-black">5)</span>
                    </div>
                    <b-form-group
                      label-class="field-label"
                      label="Name of Beneficiary 受供奉者姓氏"
                      label-for="fifthBeneficiary"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="fifthBeneficiary"
                        vid="fifthBeneficiary"
                        rules=""
                      >
                        <b-form-input
                          id="fifthBeneficiary"
                          ref="fifthBeneficiary"
                          v-model="fifthBeneficiary"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Relationship 关系"
                      label-for="fifthRelationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="fifthRelationship"
                        vid="fifthRelationship"
                        rules=""
                      >
                        <b-form-input
                          id="fifthRelationship"
                          ref="fifthRelationship"
                          v-model="fifthRelationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="text-bold-black">6)</span>
                    </div>
                    <b-form-group
                      label-class="field-label"
                      label="Name of Beneficiary 受供奉者姓氏"
                      label-for="sixthBeneficiary"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="sixthBeneficiary"
                        vid="sixthBeneficiary"
                        rules=""
                      >
                        <b-form-input
                          id="sixthBeneficiary"
                          ref="sixthBeneficiary"
                          v-model="sixthBeneficiary"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Relationship 关系"
                      label-for="sixthRelationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="sixthRelationship"
                        vid="sixthRelationship"
                        rules=""
                      >
                        <b-form-input
                          id="sixthRelationship"
                          ref="sixthRelationship"
                          v-model="sixthRelationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="officeDetailsRef"
                title="For office use"
                class="custom-collapse-icon"
                :is-visible="officeCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">For office use</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date of Installation 安装日期"
                      vid="installationDate"
                      rules=""
                    >
                      <b-form-group
                        label="Date of Installation 安装日期"
                        label-class="field-label"
                        label-for="installationDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="installationDate"
                            ref="installationDate"
                            v-model="installationDate"
                            class="form-control flat-pickr-group"
                            placeholder="DD-MM-YYYY"
                            :config="flatPickrConfigWithoutTime"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Time 时间"
                      vid="installationTime"
                      rules=""
                    >
                      <b-form-group
                        label="Time 时间"
                        label-class="field-label"
                        label-for="installationTime"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="installationTime"
                            ref="installationTime"
                            v-model="installationTime"
                            class="form-control flat-pickr-group"
                            placeholder="10:00 am"
                            :config="flatPickrConfigTime"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
            <div v-if="!editFormInOtherStatus">
              <b-card
                v-if="requestForm.status == 'Ready to sign'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Ready to Sign?
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
                </b-card-text>

                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  @click="startSignProcess"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Sign now</span>
                </b-button>
              </b-card>
              <b-card
                v-if="requestForm.status == 'Signing'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Start signing
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 3 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    v-if="isPurchaserSign"
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="checkedBySign"
                    title="Checked By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Checked By"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="inChargeSign"
                    title="Person In Charge"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Person In Charge"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
                </b-card-text>
              </b-card>

              <b-card
                v-if="requestForm.status == 'Signed and Sent'"
                class="mt-2 service-forms-card"
              >
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 3 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Checked By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Person In Charge"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click="resendSignedFormModal"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span>Resend signed form</span>
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
            <div class="d-form__action-btn">
              <b-button
                variant="outline-primary"
                class="mt-2"
                style="background: #FFF;"
                :to="{ name: 'operation-service-requests-show', params: { id: serviceRequest._id } }"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                  size="16"
                />
                <span>Return to {{ serviceRequest.stringID }}</span>
              </b-button>
              <b-button
                v-if="!editFormInOtherStatus && requestForm.status != 'To prepare'"
                variant="outline-primary"
                class="mt-2 ml-auto"
                style="background: #FFF;"
                @click="editForm"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-col>
          <b-col
            class="content-header-right my-1 d-md-block"
            style="position: relative;"
            md="9"
            cols="12"
          >
            <!-- Table Container Card -->
            <b-card
              no-body
              class="mb-0"
            >
              <div class="urn-form-wrapper d-form_wrapper__adjust-margin">
                <div class="orn-form-header mb-75 d-flex">
                  <div class="d-form-logo_section logo-adjustment" />
                  <div class="d-form-company-section-tag">
                    <h2 class="c-title-top">
                      富貴山莊
                    </h2>
                    <div class="d-flex">
                      <h2 class="c-title-bottom">
                        Mount Prajna Ltd.
                      </h2>
                      <div class="c-info-des">
                        <p>
                          (Co. Reg. No. 200401183W)
                        </p>
                        <p>
                          (GST Group Reg. No. M90364395L)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="urn-repository-order_section">
                  <div class="b-form-section-title my-2">
                    <h3>Pedestal installation order 神主牌安位嘱咐书</h3>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-130"
                        label-cols="4"
                        label-cols-lg="3"
                        label-cols-md="4"
                        label-cols-sm="5"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>I as Purchaser*,</span>
                          <br>
                          <span>我</span>
                        </template>
                        <div
                          v-if="currentCustomerNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerName', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': customerNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="customerNameImage"
                            :src="customerNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-80"
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>NRIC*</span>
                          <br>
                          <span>身份證號碼</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerNRIC', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-130"
                        label-cols="4"
                        label-cols-lg="3"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>I as Next of Kin*,</span>
                          <br>
                          <span>我</span>
                        </template>
                        <div
                          v-if="currentKinNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinName', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': kinNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="kinNameImage"
                            :src="kinNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-80"
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>NRIC*</span>
                          <br>
                          <span>身份證號碼</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinNRIC', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="d-form-warning-text-block">
                    <p class="d-form-warning-text">
                      Being the purchaser / next-of-kin of purchaser do hereby order that the pedestal described below be installed:
                    </p>
                    <p class="d-form-warning-text">
                      本人为购者 / 本人为购者至亲，仅此嘱咐，以下所列明的神主牌位已可安位。
                    </p>
                  </div>
                  <b-row>
                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>File No.*</span>
                          <br>
                          <span>文件号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || fileNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('fileNo', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fileNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ fileNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-class="label-width-120"
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Pedestal No.*:</span>
                          <br>
                          <span>神主牌位</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || pedestalNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('pedestalNo', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !pedestalNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ pedestalNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-class="label-width-160"
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                      >
                        <template #label>
                          <span>Name of Beneficiary:</span>
                          <br>
                          <span>受供奉者姓氏</span>
                        </template>
                        <div
                          class="d-form-input-field-wrapper"
                        >
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              1)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || firstBeneficiary), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('firstBeneficiary', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !firstBeneficiary"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ firstBeneficiary || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              2)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || secondBeneficiary), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('secondBeneficiary', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !secondBeneficiary"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ secondBeneficiary || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              3)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || thirdBeneficiary), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('thirdBeneficiary', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !thirdBeneficiary"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ thirdBeneficiary || '-' }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-form-input-field-wrapper mt-1"
                        >
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              4)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || fourthBeneficiary), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('fourthBeneficiary', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fourthBeneficiary"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ fourthBeneficiary || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              5)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || fifthBeneficiary), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('fifthBeneficiary', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fifthBeneficiary"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ fifthBeneficiary || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              6)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || sixthBeneficiary), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('sixthBeneficiary', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !sixthBeneficiary"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ sixthBeneficiary || '-' }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-class="label-width-160"
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                      >
                        <template #label>
                          <span>Relationship:</span>
                          <br>
                          <span>关系</span>
                        </template>
                        <div
                          class="d-form-input-field-wrapper"
                        >
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              1)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || firstRelationship), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('firstRelationship', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !firstRelationship"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ firstRelationship || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              2)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || secondRelationship), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('secondRelationship', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !secondRelationship"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ secondRelationship || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              3)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || thirdRelationship), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('thirdRelationship', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !thirdRelationship"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ thirdRelationship || '-' }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-form-input-field-wrapper  mt-1"
                        >
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              4)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || fourthRelationship), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('fourthRelationship', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fourthRelationship"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ fourthRelationship || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              5)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || fifthRelationship), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('fifthRelationship', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fifthRelationship"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ fifthRelationship || '-' }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-form-input-wrapper"
                          >
                            <p class="m-auto">
                              6)
                            </p>
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || sixthRelationship), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('sixthRelationship', 'particularDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !sixthRelationship"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ sixthRelationship || '-' }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-5">
                    <b-col md="6">
                      <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                        <br>
                        <span>购者或至亲签名</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.purchaser-sign-modal
                        class="form-signature-box"
                        :class="{ 'signed': isPurchaserSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!isPurchaserSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="purchaserSign"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ customerName }}</p>
                        <p v-if="purchaserSignDate">
                          Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="d-form-border mt-4">
                    <h5 class="d-form-office-use-title">
                      For office use 公务用栏
                    </h5>
                    <div
                      class="d-form-border-bottom"
                    >
                      <b-row>
                        <b-col
                          md="8"
                          class="pr-0 padding-right-auto"
                        >
                          <div class="padding-20">
                            <b-form-group
                              label-for="input-default"
                              label-cols-md="3"
                              label-cols="12"
                              label-class="label-width-150"
                              class="mb-0"
                            >
                              <template #label>
                                <span>Date of Installation:</span>
                                <br>
                                <span>安装日期</span>
                              </template>
                              <div
                                class="empty-input__text"
                                :class="{ 'bg-white': (requestForm.status != 'To prepare' || installationDate), 'edit-mode': editFormInOtherStatus }"
                                @click="handleClickDateField('installationDate', 'officeDetailsRef')"
                              >
                                <p
                                  v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !installationDate"
                                  class="placeholder__text"
                                />
                                <p v-else>
                                  {{ installationDate || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </div>
                        </b-col>
                        <b-col
                          md="4"
                          class="pl-0 padding-left-auto"
                        >
                          <div class="padding-20">
                            <b-form-group
                              label-for="input-default"
                              label-cols-md="2"
                              label-cols="12"
                              label-class="label-width-80"
                            >
                              <template #label>
                                <span>Time:</span>
                                <br>
                                <span>时间</span>
                              </template>
                              <div
                                class="empty-input__text"
                                :class="{ 'bg-white': (requestForm.status != 'To prepare' || installationTime), 'edit-mode': editFormInOtherStatus }"
                                @click="handleClickDateField('installationTime', 'officeDetailsRef')"
                              >
                                <p
                                  v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !installationTime"
                                  class="placeholder__text"
                                />
                                <p v-else>
                                  {{ installationTime || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <b-row>
                      <b-col
                        md="6"
                        class="d-form-border-right pr-0 border-right-none padding-right-auto"
                      >
                        <div class="padding-20">
                          <p class="d-form-signature-title">
                            Check by 检查人员:
                          </p>
                          <div
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.checked-sign-modal
                            class="form-signature-box"
                            :class="{ 'signed': checkedBySign, 'edit-mode': editFormInOtherStatus }"
                            :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                          >
                            <div class="form-signature-content">
                              <span
                                v-if="!checkedBySign"
                                class="form-signature-text"
                              >
                                Sign here
                              </span>
                              <b-img
                                v-else
                                :src="checkedBySignSrc"
                                alt="sign"
                              />
                            </div>
                          </div>
                          <div class="signature-date-block">
                            <p>Name 姓名: {{ checkedBySignName }}</p>
                            <p v-if="checkedBySign">
                              Date 日期: {{ dateFormatWithTime(checkedBySignDate) }}
                            </p>
                            <p v-else>
                              Date 日期:
                            </p>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="6"
                        class="pl-0 padding-left-auto"
                      >
                        <div class="padding-20">
                          <p class="d-form-signature-title">
                            Person in Charge 服务人员:
                          </p>
                          <div
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.in-charge-sign-modal
                            class="form-signature-box"
                            :class="{ 'signed': inChargeSign, 'edit-mode': editFormInOtherStatus }"
                            :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                          >
                            <div class="form-signature-content">
                              <span
                                v-if="!inChargeSign"
                                class="form-signature-text"
                              >
                                Sign here
                              </span>
                              <img
                                v-else
                                :src="inChargeSignSrc"
                                alt="sign"
                              >
                            </div>
                          </div>
                          <div class="signature-date-block">
                            <p>Name 姓名: {{ inChargeSignName }}</p>
                            <p v-if="inChargeSign">
                              Date 日期: {{ dateFormatWithTime(inChargeSignDate) }}
                            </p>
                            <p v-else>
                              Date 日期:
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-if="requestForm.status == 'To prepare'"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Pedestal Installation Order Form</span>
          </p>
          <b-nav
            :class="{'d-form_action-btn_tablet': isMobile()}"
            class="padding-left-right-20"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Pedestal Installation Order Form</span>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-btn__mob-li': isMobile(), 'ml-auto margin-right-5': !isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                @click="submitSaveForm('saveOnly')"
              >
                Save changes
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-second_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                @click="makeFormNotRequired"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Not Required</span>
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-third_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                @click="submitSaveForm('readyToSign')"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Ready to Sign</span>
              </b-button>
            </li>
          </b-nav>
        </div>

        <div
          v-if="editFormInOtherStatus"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Editing Pedestal Installation Order Form</span>
          </p>
          <b-nav
            class="padding-left-right-20"
            :class="{'d-form_action-btn_tablet': isMobile()}"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Editing Pedestal Installation Order Form</span>
            </li>
            <li
              :class="{'ml-auto margin-right-5': !isMobile(), 'mob__cancel-btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                class="action-cancel_btn"
                @click="editFormInOtherStatus = false; makeFormValues()"
              >
                Cancel Editing
              </b-button>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-editing__btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                class="action-done_btn"
                @click="submitSaveFormConfirmation()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Done</span>
              </b-button>
            </li>
          </b-nav>
        </div>
      </b-form>
    </validation-observer>
    <!-- Checked By signature modal -->
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser’s / Next of Kin’s Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- Checked By signature modal -->
    <b-modal
      id="checked-sign-modal"
      ref="checked-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Checked By Signature"
      @ok="closeCheckedSignModal"
      @cancel="closeCheckedSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Checked By</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCheckedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCheckedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ Checked By signature modal -->
    <!-- witnessed signature modal -->
    <b-modal
      id="in-charge-sign-modal"
      ref="in-charge-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Person In Charge Signature"
      @ok="closeInChargeSignModal"
      @cancel="closeInChargeSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Person in Charge</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearInChargeSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveInChargeSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified by signature modal -->
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- kin name -->
    <!-- office use section modal -->
    <b-modal
      id="office-use-section-modal"
      ref="office-use-section-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="For office use 公务用栏"
      @ok="saveOfficeSectionData"
      @cancel="closeOfficeSectionModal"
    >
      <div class="">
        <validation-provider
          #default="{ errors }"
          name="Date"
          vid="date"
          rules="required"
        >
          <b-form-group
            label="Installation Date*"
            label-cols-md="3"
            label-for="date"
            :class="{ 'is-invalid': errors.length }"
          >
            <b-input-group>
              <flat-pickr
                id="date"
                v-model="installationDateVal"
                class="form-control flat-pickr-group"
                placeholder="Select a Date"
                :config="flatPickrConfigWithoutTime"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Time"
          vid="time"
          rules="required"
        >
          <b-form-group
            label="Installation Time*"
            label-cols-md="3"
            label-for="time"
            :class="{ 'is-invalid': errors.length }"
          >
            <b-input-group>
              <flat-pickr
                id="time"
                v-model="installationTimeVal"
                class="form-control flat-pickr-group"
                placeholder="Select a Time"
                :config="flatPickrConfigTime"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="ClockIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
    <!-- submit signed form -->
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitSaveForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BNav, BForm, BInputGroupAppend,
  BInputGroup, BTab, BTabs, BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'
// eslint-disable-next-line
  import 'quill/dist/quill.core.css'
// eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BNav,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    BTab,
    BTabs,
    BFormTextarea,

    flatPickr,
    quillEditor,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      checkedBySign: false,
      checkedBySignSrc: '',
      checkedBySignDate: '',
      checkedBySignName: '',
      inChargeSign: false,
      inChargeSignSrc: '',
      inChargeSignDate: '',
      inChargeSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      purchaserDetailsCollapse: true,
      kinDetailsCollapse: false,
      officeCollapse: false,
      particularCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNRIC: '',
      fileNo: '',
      pedestalNo: '',
      firstBeneficiary: '',
      firstRelationship: '',
      secondBeneficiary: '',
      secondRelationship: '',
      thirdBeneficiary: '',
      thirdRelationship: '',
      fourthBeneficiary: '',
      fourthRelationship: '',
      fifthBeneficiary: '',
      fifthRelationship: '',
      sixthBeneficiary: '',
      sixthRelationship: '',
      installationDateVal: '',
      installationDate: '',
      installationTimeVal: '',
      installationTime: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingInChargeSignSrcObject: {},
      existingCheckedBySignSrcObject: {},
      existingPurchaserSignObject: {},
      existingInstallationDateObject: {},
      existingInstallationTimeObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigTime: {
        wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true,
      },
      required,
    }
  },
  created() {
    this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      if (this.requestForm.values.length) {
        const firstBeneficiaryObject = this.requestForm.values.find(o => o.key === 'firstBeneficiary')
        if (firstBeneficiaryObject) {
          this.firstBeneficiary = firstBeneficiaryObject.value
        }
        const firstRelationshipObject = this.requestForm.values.find(o => o.key === 'firstRelationship')
        if (firstRelationshipObject) {
          this.firstRelationship = firstRelationshipObject.value
        }
        const secondBeneficiaryObject = this.requestForm.values.find(o => o.key === 'secondBeneficiary')
        if (secondBeneficiaryObject) {
          this.secondBeneficiary = secondBeneficiaryObject.value
        }
        const secondRelationshipObject = this.requestForm.values.find(o => o.key === 'secondRelationship')
        if (secondRelationshipObject) {
          this.secondRelationship = secondRelationshipObject.value
        }
        const thirdBeneficiaryObject = this.requestForm.values.find(o => o.key === 'thirdBeneficiary')
        if (thirdBeneficiaryObject) {
          this.thirdBeneficiary = thirdBeneficiaryObject.value
        }
        const thirdRelationshipObject = this.requestForm.values.find(o => o.key === 'thirdRelationship')
        if (thirdRelationshipObject) {
          this.thirdRelationship = thirdRelationshipObject.value
        }
        const fourthBeneficiaryObject = this.requestForm.values.find(o => o.key === 'fourthBeneficiary')
        if (fourthBeneficiaryObject) {
          this.fourthBeneficiary = fourthBeneficiaryObject.value
        }
        const fourthRelationshipObject = this.requestForm.values.find(o => o.key === 'fourthRelationship')
        if (fourthRelationshipObject) {
          this.fourthRelationship = fourthRelationshipObject.value
        }
        const fifthBeneficiaryObject = this.requestForm.values.find(o => o.key === 'fifthBeneficiary')
        if (fifthBeneficiaryObject) {
          this.fifthBeneficiary = fifthBeneficiaryObject.value
        }
        const fifthRelationshipObject = this.requestForm.values.find(o => o.key === 'fifthRelationship')
        if (fifthRelationshipObject) {
          this.fifthRelationship = fifthRelationshipObject.value
        }
        const sixthBeneficiaryObject = this.requestForm.values.find(o => o.key === 'sixthBeneficiary')
        if (sixthBeneficiaryObject) {
          this.sixthBeneficiary = sixthBeneficiaryObject.value
        }
        const sixthRelationshipObject = this.requestForm.values.find(o => o.key === 'sixthRelationship')
        if (sixthRelationshipObject) {
          this.sixthRelationship = sixthRelationshipObject.value
        }
        const installationTimeObject = this.requestForm.values.find(o => o.key === 'installationTime')
        if (installationTimeObject && installationTimeObject.value) {
          const time = new Date(`1970-01-01T${installationTimeObject.value}:00Z`)
            .toLocaleTimeString('en-US',
              {
                timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric',
              })
          this.existingInstallationTimeObject = installationTimeObject
          this.installationTime = time
          this.installationTimeVal = installationTimeObject.value
        }
        const installationDateObject = this.requestForm.values.find(o => o.key === 'installationDate')
        if (installationDateObject) {
          this.existingInstallationDateObject = installationDateObject
          this.installationDate = installationDateObject.value
          this.installationDateVal = installationDateObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const checkedBySignSrcObject = this.requestForm.values.find(o => o.key === 'checkedBySignSrc')
        if (checkedBySignSrcObject) {
          this.existingCheckedBySignSrcObject = checkedBySignSrcObject
          this.checkedBySignSrc = checkedBySignSrcObject.value
          this.checkedBySign = true
          this.checkedBySignDate = checkedBySignSrcObject.date
          this.checkedBySignName = checkedBySignSrcObject.name
        }
        const inChargeSignSrcObject = this.requestForm.values.find(o => o.key === 'inChargeSignSrc')
        if (inChargeSignSrcObject) {
          this.existingInChargeSignSrcObject = inChargeSignSrcObject
          this.inChargeSignSrc = inChargeSignSrcObject.value
          this.inChargeSign = true
          this.inChargeSignDate = inChargeSignSrcObject.date
          this.inChargeSignName = inChargeSignSrcObject.name
        }
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        const purchaserSignObj = {
          key: 'purchaserSign',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSign = data
          this.isPurchaserSign = true
          purchaserSignObj.date = new Date()
          this.existingPurchaserSignObject = purchaserSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.checkedBySign && this.inChargeSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeCheckedSignModal() {
      this.$root.$emit('bv::hide::modal', 'checked-sign-modal', '')
    },
    clearCheckedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveCheckedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'checked-sign-modal', '')
        const checkedBySignObj = {
          key: 'checkedBySignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.checkedBySignSrc = data
          this.checkedBySign = true
          checkedBySignObj.date = new Date()
          checkedBySignObj.name = this.user.name
          checkedBySignObj.admin = this.user._id
          this.existingCheckedBySignSrcObject = checkedBySignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign && this.inChargeSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(checkedBySignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeInChargeSignModal() {
      this.$root.$emit('bv::hide::modal', 'in-charge-sign-modal', '')
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    closeOfficeSectionModal() {
      this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
    },
    saveOfficeSectionData() {
      this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
      const installationDateObject = {
        key: 'installationDate',
        value: this.installationDateVal,
      }
      const installationTimeObject = {
        key: 'installationTime',
        value: this.installationTimeVal,
      }
      if (this.editFormInOtherStatus || this.requestForm.status === 'To prepare') {
        this.existingInstallationDateObject = installationDateObject
        this.existingInstallationTimeObject = installationTimeObject
        this.installationDate = this.installationDateVal
        this.installationTime = this.installationTimeVal
      } else {
        const formData = new FormData()
        formData.append('installationDateObject', JSON.stringify(installationDateObject))
        formData.append('installationTimeObject', JSON.stringify(installationTimeObject))

        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/office-section/pedestal-order`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
              this.makeFormValues()
              this.$swal({
                title: 'Office Section Data Saved',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    clearInChargeSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveInChargeSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'in-charge-sign-modal', '')
        const inChargeSignObj = {
          key: 'inChargeSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.inChargeSignSrc = data
          this.inChargeSign = true
          inChargeSignObj.date = new Date()
          inChargeSignObj.name = this.user.name
          inChargeSignObj.admin = this.user._id
          this.existingInChargeSignSrcObject = inChargeSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign && this.checkedBySign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(inChargeSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.requestForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    async handleClick(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].focus()
      }
    },
    async handleClickDateField(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].fp.open()
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.currentCustomerNameType = type
            }
          })
      } else {
        this.currentCustomerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    submitSaveFormConfirmation() {
      if (this.requestForm.status === 'Signed and Sent') {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitSaveForm()
      }
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
      // this.editFormInOtherStatus = false
      // this.makeFormValues()
    },
    submitSaveForm(option = null, submit = false) {
      this.$refs.serviceRequestFormSave.validate().then(success => {
        let hasCustomerImage = true
        if (this.currentCustomerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        // let hasKinNameImage = true
        // if (this.currentKinNameType === 'canvas' && !this.kinNameImage) {
        //   hasKinNameImage = false
        //   this.kinNameImageError = true
        // }
        if ((option && option === 'saveOnly') || (success && hasCustomerImage)) {
          const formData = new FormData()
          if (option && option === 'readyToSign') {
            formData.append('readyToSign', true)
          }
          const valueArray = []
          let srValueArray = this.serviceRequest.formValues
          const sfValueObject = {}
          const srValueObject = {}
          const customerNameObj = {
            key: 'customerName',
          }
          if (this.currentCustomerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNameObj.key).concat(customerNameObj)

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNRICObj.key).concat(customerNRICObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.currentKinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNameObj.key).concat(kinNameObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNRICObj.key).concat(kinNRICObj)

          srValueObject.fileNo = this.fileNo

          const firstBeneficiaryObj = {
            key: 'firstBeneficiary',
            value: this.firstBeneficiary,
          }
          valueArray.push(firstBeneficiaryObj)

          const firstRelationshipObj = {
            key: 'firstRelationship',
            value: this.firstRelationship,
          }
          valueArray.push(firstRelationshipObj)

          const secondBeneficiaryObj = {
            key: 'secondBeneficiary',
            value: this.secondBeneficiary,
          }
          valueArray.push(secondBeneficiaryObj)

          const secondRelationshipObj = {
            key: 'secondRelationship',
            value: this.secondRelationship,
          }
          valueArray.push(secondRelationshipObj)

          const thirdBeneficiaryObj = {
            key: 'thirdBeneficiary',
            value: this.thirdBeneficiary,
          }
          valueArray.push(thirdBeneficiaryObj)

          const thirdRelationshipObj = {
            key: 'thirdRelationship',
            value: this.thirdRelationship,
          }
          valueArray.push(thirdRelationshipObj)

          const fourthBeneficiaryObj = {
            key: 'fourthBeneficiary',
            value: this.fourthBeneficiary,
          }
          valueArray.push(fourthBeneficiaryObj)

          const fourthRelationshipObj = {
            key: 'fourthRelationship',
            value: this.fourthRelationship,
          }
          valueArray.push(fourthRelationshipObj)

          const fifthBeneficiaryObj = {
            key: 'fifthBeneficiary',
            value: this.fifthBeneficiary,
          }
          valueArray.push(fifthBeneficiaryObj)

          const fifthRelationshipObj = {
            key: 'fifthRelationship',
            value: this.fifthRelationship,
          }
          valueArray.push(fifthRelationshipObj)

          const sixthBeneficiaryObj = {
            key: 'sixthBeneficiary',
            value: this.sixthBeneficiary,
          }
          valueArray.push(sixthBeneficiaryObj)

          const sixthRelationshipObj = {
            key: 'sixthRelationship',
            value: this.sixthRelationship,
          }
          valueArray.push(sixthRelationshipObj)

          let inChargeSignVal = 0
          let purchaserSignVal = 0
          let checkedBySignVal = 0
          if (this.existingInChargeSignSrcObject.key) {
            inChargeSignVal = 1
            valueArray.push(this.existingInChargeSignSrcObject)
          }
          if (this.existingCheckedBySignSrcObject.key) {
            checkedBySignVal = 1
            valueArray.push(this.existingCheckedBySignSrcObject)
          }
          if (this.existingPurchaserSignObject.key) {
            purchaserSignVal = 1
            valueArray.push(this.existingPurchaserSignObject)
          }
          const installationDateObj = {
            key: 'installationDate',
            value: this.installationDate,
          }
          valueArray.push(installationDateObj)
          const installationTimeObj = {
            key: 'installationTime',
            value: this.installationTime,
          }
          valueArray.push(installationTimeObj)

          let allSigned = false
          if (inChargeSignVal && purchaserSignVal && checkedBySignVal) {
            allSigned = true
          }

          // let valueChanged = false
          // if (this.requestForm.status === 'Signed and Sent') {
          //   const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
          //   if (difference.length) {
          //     const otherValueChanged = difference.find(o => !['installationDate', 'installationTime'].includes(o.key))
          //     if (otherValueChanged) {
          //       valueChanged = true
          //     }
          //   }
          // }

          formData.append('values', JSON.stringify(valueArray))
          formData.append('srValues', JSON.stringify(srValueArray))
          formData.append('sfValues', JSON.stringify(sfValueObject))
          formData.append('srValueObject', JSON.stringify(srValueObject))
          formData.append('allSigned', allSigned)
          formData.append('currentStatus', this.requestForm.status)
          formData.append('formName', this.requestForm.formID.name)
          formData.append('serviceRequest', this.serviceRequest._id)
          formData.append('serviceForm', this.serviceRequest.serviceForm._id)
          formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                await this.$emit('update:serviceRequest', response.data.sr)
                this.makeFormValues()
                this.editFormInOtherStatus = false
                if (option && option === 'edited' && response.data.data.status === 'Signed and Sent' && submit) {
                  this.submitSignedForm('edited')
                } else if (response.data.data.status === 'Signing' && allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Form Data Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
