<template>
  <div>
    <validation-observer
      ref="serviceRequestFormSave"
    >
      <b-form @submit.prevent="submitSaveForm">
        <b-row
          class="content-header v-sticky-sidebar-container service_request_task collapse__form-wrapper"
          :class="{ 'white-background': !editFormInOtherStatus, 'green-background': editFormInOtherStatus }"
          style="border-radius: 6px;"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left my-1 top-info-right"
            cols="12"
            md="3"
          >
            <div
              class="user_block ac_type"
              style="float: none;"
            >
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="">
                  {{ requestForm.status }}
                </h5>
              </div>
            </div>

            <b-card
              v-if="editFormInOtherStatus"
              class="mt-2 service-forms-card success-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">you're now</span>
                  Editing this form
                </h3>
              </template>
              <b-card-text
                class="signing-stage__title"
              >
                <span>After submitting the signed form, the system will generate a signed PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>

            <app-collapse
              v-if="requestForm.status == 'To prepare' || editFormInOtherStatus"
              accordion
              class="mt-2 service-form-collapse"
            >
              <app-collapse-item
                ref="purchaserDetailsRef"
                :is-visible="purchaserDetailsCollapse"
                title="Purchaser Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Purchaser Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">At the behest of 在购者</span>
                    </div>
                    <b-form-group
                      v-if="currentCustomerNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="At the behest of"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="customerName"
                          v-model="customerName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': customerNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeCustomerNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="customerNameImage"
                        v-b-modal.customer-name-modal
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="NRIC: (Purchaser) 身份证号码"
                      label-class="field-label label-required-star"
                      label-for="customerNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC: (Purchaser)"
                        vid="customerNRIC"
                        rules="required"
                      >
                        <b-form-input
                          id="customerNRIC"
                          ref="customerNRIC"
                          v-model="customerNRIC"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="kinDetailsRef"
                :is-visible="kinDetailsCollapse"
                title="Next of Kin Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Next of Kin Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">Or Next of Kin 或是其至亲</span>
                    </div>
                    <b-form-group
                      v-if="currentKinNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="kinName"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="kinName"
                          v-model="kinName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': kinNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeKinNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="kinNameImage"
                        v-b-modal.kin-name-modal
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentKinNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="NRIC 身份证号码"
                      label-class="field-label"
                      label-for="kinNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="kinNRIC"
                        vid="kinNRIC"
                        rules=""
                      >
                        <b-form-input
                          id="kinNRIC"
                          ref="kinNRIC"
                          v-model="kinNRIC"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="fileDetailsRef"
                :is-visible="fileDetailsCollapse"
                title="File No. and Niche No."
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">File No. and Niche No.</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="File No. 文件号码"
                      label-class="field-label label-required-star"
                      label-for="fileNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File No."
                        vid="fileNo"
                        rules="required"
                      >
                        <b-form-input
                          id="fileNo"
                          ref="fileNo"
                          v-model="fileNo"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Niche No. 福位编号"
                      label-class="field-label label-required-star"
                      label-for="nicheNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Niche No."
                        vid="nicheNo"
                        rules=""
                      >
                        <b-form-input
                          id="nicheNo"
                          ref="nicheNo"
                          v-model="nicheNo"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="particularDetailsRef"
                title="Particulars"
                class="custom-collapse-icon"
                :is-visible="particularCollapse"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Particulars</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">Name of Beneficiary 收益者姓名</span>
                    </div>
                    <b-form-group
                      v-if="currentBeneficiaryNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name of Beneficiary"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="beneficiaryName"
                          v-model="beneficiaryName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': beneficiaryNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeBeneficiaryNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="beneficiaryNameImage"
                        v-b-modal.beneficiary-name-modal
                        :src="beneficiaryNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentBeneficiaryNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeBeneficiaryNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeBeneficiaryNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Relationship 关系"
                      label-class="field-label label-required-star"
                      label-for="relationship"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Relationship"
                        vid="relationship"
                        rules="required"
                      >
                        <b-form-input
                          id="relationship"
                          ref="relationship"
                          v-model="relationship"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Age 年龄"
                      label-class="field-label label-required-star"
                      label-for="age"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Age"
                        vid="age"
                        rules="required"
                      >
                        <b-form-input
                          id="age"
                          ref="age"
                          v-model="age"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Departed on"
                      vid="departedDate"
                      rules="required"
                    >
                      <b-form-group
                        label="Departed on 逝世日期"
                        label-class="field-label label-required-star"
                        label-for="departedDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="departedDate"
                            ref="departedOn"
                            v-model="departedOn"
                            class="form-control flat-pickr-group"
                            placeholder="Departed Date"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Birth Date"
                      vid="birthDate"
                      rules="required|date:MM/DD/YYYY"
                    >
                      <b-form-group
                        label="Birth Date 出生日期"
                        label-class="field-label label-required-star"
                        label-for="birthDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <!-- <b-input-group>
                          <flat-pickr
                            id="birthDate"
                            ref="birthDate"
                            v-model="birthDate"
                            class="form-control flat-pickr-group"
                            placeholder="Birth Date"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group> -->
                        <cleave
                          id="birthDate"
                          ref="birthDate"
                          v-model="birthDate"
                          class="form-control flat-pickr-group"
                          :raw="false"
                          :options="cleaveOptions.delimiter"
                          placeholder="Birth Date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Religion 宗教"
                      label-class="field-label label-required-star"
                      label-for="religion"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Religion"
                        vid="religion"
                        rules="required"
                      >
                        <b-form-input
                          id="religion"
                          ref="religion"
                          v-model="religion"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Religion"
                      rules="required"
                    >
                      <b-form-group
                        label="Religion 宗教"
                        label-class="field-label label-required-star"
                        label-for="religion"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <v-select
                          id="religion"
                          ref="religion"
                          v-model="religion"
                          label="text"
                          placeholder="Select an option"
                          :options="religionOptions"
                          :reduce="name => name.value"
                          :clearable="false"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="gender"
                      rules="required"
                    >
                      <b-form-group
                        label="Gender 性別"
                        label-class="field-label label-required-star"
                        label-for="gender"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <v-select
                          id="gender"
                          ref="gender"
                          v-model="gender"
                          label="text"
                          placeholder="Select an option"
                          :options="genderOptions"
                          :reduce="name => name.value"
                          :clearable="false"
                          @input="makeGenderArray()"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Undertaker 寿板店"
                      label-class="field-label label-required-star"
                      label-for="undertaker"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="undertaker"
                        vid="undertaker"
                        rules="required"
                      >
                        <b-form-input
                          id="undertaker"
                          ref="undertaker"
                          v-model="undertaker"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Place of Cremation 焚化地点"
                      label-class="field-label label-required-star"
                      label-for="cremationPlace"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Place of Cremation"
                        vid="cremationPlace"
                        rules="required"
                      >
                        <b-form-input
                          id="cremationPlace"
                          ref="cremationPlace"
                          v-model="cremationPlace"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date of Cremation"
                      vid="cremationDate"
                      rules="required|date:MM/DD/YYYY"
                    >
                      <b-form-group
                        label="Date of Cremation 焚化日期"
                        label-class="field-label label-required-star"
                        label-for="cremationDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <!-- <b-input-group>
                          <flat-pickr
                            id="cremationDate"
                            ref="cremationDate"
                            v-model="cremationDate"
                            class="form-control flat-pickr-group"
                            placeholder="Cremation Date"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group> -->
                        <cleave
                          id="cremationDate"
                          ref="cremationDate"
                          v-model="cremationDate"
                          class="form-control flat-pickr-group"
                          :raw="false"
                          :options="cleaveOptions.delimiter"
                          placeholder="Cremation Date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Service Address 治丧处"
                      label-class="field-label label-required-star"
                      label-for="serviceAddress"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Service Address"
                        vid="serviceAddress"
                        rules="required"
                      >
                        <b-form-input
                          id="serviceAddress"
                          ref="serviceAddress"
                          v-model="serviceAddress"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Contact No. 联络电话"
                      label-class="field-label"
                      label-for="contactNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="contactNo"
                        vid="contactNo"
                        rules=""
                      >
                        <b-form-input
                          id="contactNo"
                          ref="contactNo"
                          v-model="contactNo"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date of Installation"
                      vid="installationDate"
                      rules=""
                    >
                      <b-form-group
                        label="Date of Installation 安位日期"
                        label-class="field-label"
                        label-for="installationDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="installationDate"
                            ref="installationDate"
                            v-model="installationDate"
                            class="form-control flat-pickr-group"
                            placeholder="Installation Date"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-for="input-default"
                    >
                      <b-form-checkbox
                        v-model="firstInstallation"
                        value="firstInstallation"
                        class="field-label"
                      >
                        1st Installation
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Subsequent Installation Fee"
                      label-class="field-label"
                      label-for="input-default"
                    >
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <b-form-checkbox
                            v-model="subsequentInstallation"
                            value="subsequentInstallation"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          id="subsequentInstallationFee"
                          v-model="subsequentInstallationFee"
                          placeholder="Enter"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Receipt No 收据号码"
                      label-class="field-label"
                      label-for="receiptNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="receiptNo"
                        vid="receiptNo"
                        rules=""
                      >
                        <b-form-input
                          id="receiptNo"
                          ref="receiptNo"
                          v-model="receiptNo"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="officeDetailsRef"
                :is-visible="officeDetailsCollapse"
                title="For office use"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">For office use</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label=""
                      label-for="serviceType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="serviceType"
                        vid="serviceType"
                        rules=""
                      >
                        <b-form-radio-group
                          id="serviceType"
                          ref="serviceType"
                          v-model="serviceType"
                          name="serviceType"
                          class="form-custom-input-checkbox"
                          :options="needOptions"
                          @input="serviceTypeValue = [serviceType]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Remark 备注"
                      label-for="officeRemarks"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="officeRemarks"
                        vid="officeRemarks"
                        rules=""
                      >
                        <b-form-textarea
                          id="officeRemarks"
                          ref="officeRemarks"
                          v-model="officeRemarks"
                          rows="3"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
            <div v-if="!editFormInOtherStatus">
              <b-card
                v-if="requestForm.status == 'Ready to sign'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Ready to Sign?
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
                </b-card-text>

                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  @click="startSignProcess"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Sign now</span>
                </b-button>
              </b-card>
              <b-card
                v-if="requestForm.status == 'Signing'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Start signing
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 3 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    v-if="isPurchaserSign"
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="authorizedSign"
                    title="Authorised Name"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Authorised Name"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="witnessedSign"
                    title="Installation Service Handled & Witnessed By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Installation Service Handled & Witnessed By"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
                </b-card-text>
              </b-card>

              <b-card
                v-if="requestForm.status == 'Signed and Sent'"
                class="mt-2 service-forms-card"
              >
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 3 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Authorised Name"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Installation Service Handled & Witnessed By"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click="resendSignedFormModal"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span>Resend signed form</span>
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
            <div class="d-form__action-btn">
              <b-button
                variant="outline-primary"
                class="mt-2"
                style="background: #FFF;"
                :to="{ name: 'operation-service-requests-show', params: { id: serviceRequest._id } }"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                  size="16"
                />
                <span>Return to {{ serviceRequest.stringID }}</span>
              </b-button>
              <b-button
                v-if="!editFormInOtherStatus && requestForm.status != 'To prepare'"
                variant="outline-primary"
                class="mt-2 ml-auto"
                style="background: #FFF;"
                @click="editForm"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-col>
          <b-col
            class="content-header-right my-1 d-md-block"
            style="position: relative;"
            md="9"
            cols="12"
          >
            <!-- Table Container Card -->
            <b-card
              no-body
              class="mb-0"
            >
              <div class="orn-form-wrapper d-form_wrapper__adjust-margin">
                <div
                  class="mb-1 d-flex justify-content-between"
                >
                  <div class="d-flex">
                    <div
                      class="d-form-logo_section"
                    />
                    <div class="d-form-company-section-tag">
                      <h2 class="c-title-top">
                        富貴山莊
                      </h2>
                      <div class="d-flex">
                        <h2 class="c-title-bottom">
                          Mount Prajna Ltd.
                        </h2>
                        <div class="c-info-des">
                          <p>
                            (Co. Reg. No. 200401183W)
                          </p>
                          <p>
                            (GST Group Reg. No. M90364395L)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-form-no_section">
                    <h5>
                      {{ digitalForm.formNumber }}
                    </h5>
                    <h5>
                      {{ digitalForm.revision }}
                    </h5>
                  </div>
                </div>
                <div class="urn-repository-order_section">
                  <div class="b-form-section-title">
                    <h3>
                      Urn Repository order
                    </h3>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-140"
                        label-cols-lg="3"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>At the behest of*:</span>
                          <br>
                          <span>在购者</span>
                        </template>
                        <div
                          v-if="currentCustomerNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerName', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': customerNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="customerNameImage"
                            :src="customerNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <div class="">
                        <b-form-group
                          label-class="label-width-110 mt-0"
                          label-cols="12"
                          label-cols-lg="2"
                          label="NRIC*: 身份证号码"
                          label-for="input-default"
                          class="align-items-center"
                        >
                          <template #label>
                            <span class="">NRIC*:</span>
                            <br>
                            <span>身份证号码</span>
                          </template>
                          <div class="d-flex">
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerNRIC), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('customerNRIC', 'purchaserDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerNRIC"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ customerNRIC || '-' }}
                              </p>
                            </div>
                            <span class="urn_repository-purchaser_text">(Purchaser)</span>
                          </div>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-140 mt-0"
                        label-cols-lg="3"
                        label="Or Next of Kin: 或是其至亲"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Or Next of Kin:</span>
                          <br>
                          <span>或是其至亲</span>
                        </template>
                        <div
                          v-if="currentKinNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinName', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': kinNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="kinNameImage"
                            :src="kinNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-110 mt-0"
                        label-cols-lg="2"
                        label="NRIC*: 身份证号码"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">NRIC:</span>
                          <br>
                          <span>身份证号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinNRIC', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <p class="urn_repository-paragraph">
                        that niche described hereunder is now available for the deposit of urn containing the cinerary remains of the purchaser or that of his nominee named below:
                      </p>
                      <p class="urn_repository-paragraph">
                        的嘱咐下，以下所列明的骨灰灵位，如今已可以按放瓮内装有购者或其受益人火化骨灰的骨灰瓮 。
                      </p>
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-90"
                        label-cols-lg="2"
                        label="File No. 文件号码*:"
                        label-for="input-default"
                        class="mt-2 align-items-center"
                      >
                        <template #label>
                          <span>File No.*:</span>
                          <br>
                          <span>文件号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || fileNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('fileNo', 'fileDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fileNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ fileNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-90"
                        label-cols-lg="2"
                        label="Niche No. 福位编号*:"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Niche No.*:</span>
                          <br>
                          <span>福位编号</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || nicheNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('nicheNo', 'fileDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !nicheNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ nicheNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <div class="urn_repository-text-box">
                        <p>The niche is for depositing cinerary urn only. The Management shall not be liable to the purchaser in any manner whatsoever resulting from or arising out of any loss of or damage to any other material chattels or things deposited in the niche. </p>
                        <p>骨灰位仅供安置骨灰瓮之用，其它物件一概不可随瓮安置。若有意外，本公司将不负责赔赏有关物件所蒙受的损失与破坏.</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="urn-form-particular_section">
                  <div class="urn_repository-particular">
                    <h3>
                      PARTICULARS
                    </h3>
                  </div>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-200"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>1.</span>
                            <div>
                              <span>Name of Beneficiary*:</span>
                              <br>
                              <span>收益者姓名</span>
                            </div>
                          </div>
                        </template>
                        <div
                          v-if="currentBeneficiaryNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || beneficiaryName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('beneficiaryName', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !beneficiaryName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ beneficiaryName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': beneficiaryNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="beneficiaryNameImage"
                            :src="beneficiaryNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-130"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>2.</span>
                            <div>
                              <span>Relationship*:</span>
                              <br>
                              <span>关系</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || relationship), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('relationship', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !relationship"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ relationship || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-100"
                        label-cols-lg="3"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Age*:</span>
                          <br>
                          <span>年龄</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || age), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('age', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !age"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ age || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-130"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>3.</span>
                            <div>
                              <span class="">Departed on*:</span>
                              <br>
                              <span>逝世日期</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || departedOn), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClickDateField('departedOn', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !departedOn"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ departedOn || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-100"
                        label-cols-lg="3"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Birth Date*:</span>
                          <br>
                          <span>出生日期</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || birthDate), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClickCleaveField('birthDate', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !birthDate"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ birthDate || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-130"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>4.</span>
                            <div>
                              <span>Religion*:</span>
                              <br>
                              <span>宗教</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || religion), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClickSelect('religion', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !religion"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ religion || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-100"
                        label-cols-lg="3"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Gender*:</span>
                          <br>
                          <span>性別</span>
                        </template>
                        <b-form-checkbox-group
                          v-model="genderValue"
                          :options="genderOptions"
                          name="gender"
                          class="form-custom-input-checkbox urn_repository-checkbox-label"
                          style="pointer-events: none;"
                        />
                        <!-- <b-form-radio-group
                          id="deceasedGender"
                          v-model="gender"
                          class="spaceBetweenRadio"
                        >
                          <b-form-radio
                            v-for="(option, index) in genderOptions"
                            :key="index"
                            :value="option.value"
                            class="mb-50"
                          >
                            <feather-icon
                              v-if="gender === option.value"
                              icon="CheckIcon"
                              class="mr-50"
                              size="16"
                            />
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group> -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-180"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>5.</span>
                            <div>
                              <span>Undertaker*:</span>
                              <br>
                              <span>寿板店</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || undertaker), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('undertaker', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !undertaker"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ undertaker || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-180"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>6.</span>
                            <div>
                              <span>Place of Cremation*:</span>
                              <br>
                              <span>焚化地点</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || cremationPlace), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('cremationPlace', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !cremationPlace"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ cremationPlace || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-180"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>7.</span>
                            <div>
                              <span>Date of Cremation*:</span>
                              <br>
                              <span>焚化日期</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || cremationDate), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClickCleaveField('cremationDate', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !cremationDate"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ cremationDate || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-180"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>8.</span>
                            <div>
                              <span>Service Address*:</span>
                              <br>
                              <span>治丧处</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || serviceAddress), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('serviceAddress', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !serviceAddress"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ serviceAddress || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-180"
                        label-cols-lg="4"
                        label-for="input-default"
                      >
                        <template #label>
                          <div
                            class="urn-repository-order__label"
                          >
                            <span>9.</span>
                            <div>
                              <span class="">Date of Installation:</span>
                              <br>
                              <span>安位日期</span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || installationDate), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClickDateField('installationDate', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !installationDate"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ installationDate || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="6"
                      md="6"
                    >
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-110"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Contact No.:</span>
                          <br>
                          <span>联络号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || contactNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('contactNo', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !contactNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ contactNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      md="6"
                      class="d-flex"
                    >
                      <b-form-checkbox
                        v-model="firstInstallation"
                        value="firstInstallation"
                        class="mr-1 form-custom-input-checkbox"
                        style="pointer-events: none;"
                      >
                        <span style="font-size: 13px">1st Installation</span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="subsequentInstallation"
                        value="subsequentInstallation"
                        class="form-custom-input-checkbox align-middle"
                        style="pointer-events: none;"
                      >
                        <span style="font-size: 13px;">Subsequent Installation Fee</span>
                        <br>
                        <span>${{ subsequentInstallationFee }}</span>
                      </b-form-checkbox>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="12"
                        label-class="field-label label-width-110"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span class="">Receipt No.:</span>
                          <br>
                          <span>收据号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || receiptNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('receiptNo', 'particularDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !receiptNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ receiptNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="urn_repository-bottom_tagline">
                    <p>*All prices are inclusive of prevailing goods and services tax imposed by the government of Singapore. *以上价格已包含消费税</p>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                        <br>
                        <span>购者或至亲签名</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.purchaser-sign-modal
                        class="form-signature-box"
                        :class="{ 'signed': isPurchaserSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!isPurchaserSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="purchaserSign"
                            alt="sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ customerName }}</p>
                        <p v-if="isPurchaserSign">
                          Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <p class="d-form-signature-title">Authorised Name
                        <br>
                        <span>礼仪师姓名</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.authorized-sign-modal
                        class="form-signature-box"
                        :class="{ 'signed': authorizedSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!authorizedSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="authorizedSignSrc"
                            alt="sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ authorizedSignName }}</p>
                        <p v-if="authorizedSign">
                          Date 日期: {{ dateFormatWithTime(authorizedSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="border mt-2">
                    <b-row>
                      <b-col
                        md="6"
                        class="px-2 py-2 border-right border-right-none"
                      >
                        <p class="d-form-signature-title">Installation Service Handled & Witnessed By:
                          <br>
                          <span>安装服务由以下人员处理和见证</span>
                        </p>
                        <div
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.witnessed-sign-modal
                          class="form-signature-box"
                          :class="{ 'signed': witnessedSign, 'edit-mode': editFormInOtherStatus }"
                          :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                        >
                          <div class="form-signature-content">
                            <span
                              v-if="!witnessedSign"
                              class="form-signature-text"
                            >
                              Sign here
                            </span>
                            <img
                              v-else
                              :src="witnessedSignSrc"
                              alt="sign"
                            >
                          </div>
                        </div>
                        <div class="signature-date-block">
                          <p>Name 姓名: {{ witnessedSignName }}</p>
                          <p v-if="witnessedSign">
                            Date 日期: {{ dateFormatWithTime(witnessedSignDate) }}
                          </p>
                          <p v-else>
                            Date 日期:
                          </p>
                        </div>
                      </b-col>
                      <b-col
                        md="6"
                        class="mt-2 margin-left-auto"
                      >
                        <b-form-checkbox-group
                          v-model="serviceTypeValue"
                          :options="needOptions"
                          name="need"
                          class="form-custom-input-checkbox enabled-field urn_repository-checkbox-label"
                          disabled
                        />
                        <b-form-group
                          label="Remark 备注:"
                          label-for="input-default"
                          class="mr-1 remarks"
                          label-class="fs-12 weight-500"
                        >
                          <div
                            class="empty-input__textarea"
                            :class="{ 'bg-white': (requestForm.status != 'To prepare' || officeRemarks), 'edit-mode': editFormInOtherStatus }"
                            @click="handleClick('officeRemarks', 'officeDetailsRef')"
                          >
                            <p
                              v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !officeRemarks"
                              class="placeholder__textarea"
                            />
                            <p v-else>
                              {{ officeRemarks || '-' }}
                            </p>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-if="requestForm.status == 'To prepare'"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Urn Repository Form</span>
          </p>
          <b-nav
            :class="{'d-form_action-btn_tablet': isMobile()}"
            class="padding-left-right-20"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Urn Repository Form</span>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-btn__mob-li': isMobile(), 'ml-auto margin-right-5': !isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                @click="submitSaveForm('saveOnly')"
              >
                Save changes
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-second_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                @click="makeFormNotRequired"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Not Required</span>
              </b-button>
            </li>
            <li
              class="nav-item d-form_action-third_btn"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                @click="submitSaveForm('readyToSign')"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                />
                <span class="align-middle">Mark as Ready to Sign</span>
              </b-button>
            </li>
          </b-nav>
        </div>

        <div
          v-if="editFormInOtherStatus"
          class="d-form_action-btn_group wrap-border save-nav"
        >
          <p
            v-if="isMobile()"
            class="margin-left-20 mt-50 mb-50 d-form_action-title"
          >
            <span>Editing Urn Repository Form</span>
          </p>
          <b-nav
            class="padding-left-right-20"
            :class="{'d-form_action-btn_tablet': isMobile()}"
          >
            <li
              v-if="!isMobile()"
              class="nav-item"
            >
              <span>Editing Urn Repository Form</span>
            </li>
            <li
              :class="{'ml-auto margin-right-5': !isMobile(), 'mob__cancel-btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                class="action-cancel_btn"
                @click="editFormInOtherStatus = false; makeFormValues()"
              >
                Cancel Editing
              </b-button>
            </li>
            <li
              class="nav-item"
              :class="{'d-form_action-editing__btn': isMobile()}"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                class="action-done_btn"
                @click="submitSaveFormConfirmation()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span class="align-middle">Done</span>
              </b-button>
            </li>
          </b-nav>
        </div>
      </b-form>
    </validation-observer>
    <!-- authorized signature modal -->
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser’s / Next of Kin’s Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- authorized signature modal -->
    <b-modal
      id="authorized-sign-modal"
      ref="authorized-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Authorized Signature"
      @ok="closeAuthorizedSignModal"
      @cancel="closeAuthorizedSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Authorized By</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearAuthorizedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveAuthorizedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ authorized signature modal -->
    <!-- witnessed signature modal -->
    <b-modal
      id="witnessed-sign-modal"
      ref="witnessed-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Installation Service Handled & Witnessed Signature"
      @ok="closeWitnessedSignModal"
      @cancel="closeWitnessedSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Installation Service Handled & Witnessed By</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearWitnessedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveWitnessedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified by signature modal -->
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- kin name -->
    <!-- kin name modal -->
    <b-modal
      id="beneficiary-name-modal"
      ref="beneficiary-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeBeneficiaryNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="beneficiarySignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="beneficiaryNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearBeneficiaryName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveBeneficiaryName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- beneficiary name -->
    <!-- office use section modal -->
    <b-modal
      id="office-use-section-modal"
      ref="office-use-section-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="For office use 公务用栏"
      @ok="saveOfficeSectionData"
      @cancel="closeOfficeSectionModal"
    >
      <div class="">
        <b-form-checkbox-group
          v-model="serviceTypeValueLocal"
          :options="needOptions"
          name="need"
          class="form-custom-input-checkbox"
          @input="makeCheckboxToRadio()"
        />
        <b-form-group
          label="Remark"
          label-for="office-remarks"
        >
          <validation-provider
            #default="{ errors }"
            name="Remark"
            vid="office-remarks"
            rules=""
          >
            <b-form-textarea
              id="office-remarks"
              v-model="officeRemarksVal"
              placeholder="Add Remark"
              rows="3"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
    <!-- submit signed form -->
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitSaveForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BNav, BForm, BFormTextarea, BFormCheckboxGroup, BFormCheckbox, BInputGroupAppend,
  BInputGroup, BInputGroupPrepend, BTab, BTabs, BFormRadioGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BNav,
    BForm,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BFormRadioGroup,

    flatPickr,
    vSelect,
    quillEditor,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      cleaveOptions: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        delimiter: {
          delimiter: '/',
          blocks: [2, 2, 4],
          uppercase: true,
          numericOnly: true,
        },
      },
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      religionOptions: [
        { text: 'Buddhist', value: 'Buddhist' },
        { text: 'Taoist', value: 'Taoist' },
        { text: 'Christian', value: 'Christian' },
        { text: 'Soka', value: 'Soka' },
        { text: 'Catholic', value: 'Catholic' },
        { text: 'Free thinker', value: 'Free thinker' },
        { text: 'Others', value: 'Others' },
      ],
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      authorizedSign: false,
      authorizedSignSrc: '',
      authorizedSignDate: '',
      authorizedSignName: '',
      witnessedSign: false,
      witnessedSignSrc: '',
      witnessedSignDate: '',
      witnessedSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      beneficiaryNameImageError: false,
      purchaserDetailsCollapse: true,
      kinDetailsCollapse: false,
      fileDetailsCollapse: false,
      officeDetailsCollapse: false,
      particularCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      beneficiaryNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.beneficiarySignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNRIC: '',
      fileNo: '',
      nicheNo: '',
      currentBeneficiaryNameType: 'input',
      beneficiaryName: '',
      beneficiaryNameImage: '',
      relationship: '',
      age: '',
      departedOn: '',
      birthDate: `00/00/${new Date().getFullYear()}`,
      religion: '',
      gender: '',
      genderValue: [],
      undertaker: '',
      cremationPlace: '',
      cremationDate: `00/00/${new Date().getFullYear()}`,
      serviceAddress: '',
      contactNo: '',
      installationDate: '',
      firstInstallation: '',
      subsequentInstallation: '',
      subsequentInstallationFee: '0.00',
      receiptNo: '',
      officeRemarks: '',
      officeRemarksVal: '',
      serviceType: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      serviceTypeValue: [],
      serviceTypeValueLocal: [],
      existingWitnessedSignSrcObject: {},
      existingAuthorizedSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingServiceTypeObject: {},
      existingOfficeRemarksObject: {},
      officeRemarksChanged: 0,
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      required,
    }
  },
  created() {
    this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      if (this.requestForm.values.length) {
        const beneficiaryNameObject = this.requestForm.values.find(o => o.key === 'beneficiaryName')
        if (beneficiaryNameObject) {
          if (beneficiaryNameObject.value.startsWith('data:image/')) {
            this.beneficiaryNameImage = beneficiaryNameObject.value
            this.currentBeneficiaryNameType = 'canvas'
          } else {
            this.currentBeneficiaryNameType = 'input'
            this.beneficiaryName = beneficiaryNameObject.value
          }
        }
        const relationshipObject = this.requestForm.values.find(o => o.key === 'relationship')
        if (relationshipObject) {
          this.relationship = relationshipObject.value
        }
        const ageObject = this.requestForm.values.find(o => o.key === 'age')
        if (ageObject) {
          this.age = ageObject.value
        }
        const departedOnObject = this.requestForm.values.find(o => o.key === 'departedOn')
        if (departedOnObject) {
          this.departedOn = departedOnObject.value
        }
        const birthDateObject = this.requestForm.values.find(o => o.key === 'birthDate')
        if (birthDateObject) {
          this.birthDate = birthDateObject.value
        }
        const religionObject = this.requestForm.values.find(o => o.key === 'religion')
        if (religionObject) {
          this.religion = religionObject.value
        }
        const genderObject = this.requestForm.values.find(o => o.key === 'gender')
        if (genderObject) {
          this.gender = genderObject.value
          this.genderValue = [genderObject.value]
        }
        const undertakerObject = this.requestForm.values.find(o => o.key === 'undertaker')
        if (undertakerObject) {
          this.undertaker = undertakerObject.value
        }
        const cremationPlaceObject = this.requestForm.values.find(o => o.key === 'cremationPlace')
        if (cremationPlaceObject) {
          this.cremationPlace = cremationPlaceObject.value
        }
        const cremationDateObject = this.requestForm.values.find(o => o.key === 'cremationDate')
        if (cremationDateObject) {
          this.cremationDate = cremationDateObject.value
        }
        const serviceAddressObject = this.requestForm.values.find(o => o.key === 'serviceAddress')
        if (serviceAddressObject) {
          this.serviceAddress = serviceAddressObject.value
        }
        const contactNoObject = this.requestForm.values.find(o => o.key === 'contactNo')
        if (contactNoObject) {
          this.contactNo = contactNoObject.value
        }
        const installationDateObject = this.requestForm.values.find(o => o.key === 'installationDate')
        if (installationDateObject) {
          this.installationDate = installationDateObject.value
        }
        const firstInstallationObject = this.requestForm.values.find(o => o.key === 'firstInstallation')
        if (firstInstallationObject) {
          this.firstInstallation = firstInstallationObject.value
        }
        const subsequentInstallationObject = this.requestForm.values.find(o => o.key === 'subsequentInstallation')
        if (subsequentInstallationObject) {
          this.subsequentInstallation = subsequentInstallationObject.value
        }
        const subsequentInstallationFeeObject = this.requestForm.values.find(o => o.key === 'subsequentInstallationFee')
        if (subsequentInstallationFeeObject) {
          this.subsequentInstallationFee = subsequentInstallationFeeObject.value
        }
        const receiptNoObject = this.requestForm.values.find(o => o.key === 'receiptNo')
        if (receiptNoObject) {
          this.receiptNo = receiptNoObject.value
        }
        const officeRemarksObject = this.requestForm.values.find(o => o.key === 'officeRemarks')
        if (officeRemarksObject) {
          this.existingOfficeRemarksObject = officeRemarksObject
          this.officeRemarks = officeRemarksObject.value
          this.officeRemarksVal = officeRemarksObject.value
        }
        const serviceTypeObject = this.requestForm.values.find(o => o.key === 'serviceType')
        if (serviceTypeObject) {
          this.existingServiceTypeObject = serviceTypeObject
          this.serviceType = serviceTypeObject.value
          this.serviceTypeValue = [serviceTypeObject.value]
          this.serviceTypeValueLocal = [serviceTypeObject.value]
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const authorizedSignSrcObject = this.requestForm.values.find(o => o.key === 'authorizedSignSrc')
        if (authorizedSignSrcObject) {
          this.existingAuthorizedSignSrcObject = authorizedSignSrcObject
          this.authorizedSignSrc = authorizedSignSrcObject.value
          this.authorizedSign = true
          this.authorizedSignDate = authorizedSignSrcObject.date
          this.authorizedSignName = authorizedSignSrcObject.name
        }
        const witnessedSignSrcObject = this.requestForm.values.find(o => o.key === 'witnessedSignSrc')
        if (witnessedSignSrcObject) {
          this.existingWitnessedSignSrcObject = witnessedSignSrcObject
          this.witnessedSignSrc = witnessedSignSrcObject.value
          this.witnessedSign = true
          this.witnessedSignDate = witnessedSignSrcObject.date
          this.witnessedSignName = witnessedSignSrcObject.name
        }
      } else {
        this.fileNo = this.serviceRequest.fileNo
        this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
        this.customerName = this.serviceRequest.serviceForm.customerName
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        const purchaserSignObj = {
          key: 'purchaserSign',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSign = data
          this.isPurchaserSign = true
          purchaserSignObj.date = new Date()
          this.existingPurchaserSignObject = purchaserSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.authorizedSign && this.witnessedSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeAuthorizedSignModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
    },
    clearAuthorizedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveAuthorizedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
        const authorizedSignObj = {
          key: 'authorizedSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.authorizedSignSrc = data
          this.authorizedSign = true
          authorizedSignObj.date = new Date()
          authorizedSignObj.name = this.user.name
          authorizedSignObj.admin = this.user._id
          this.existingAuthorizedSignSrcObject = authorizedSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign && this.witnessedSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(authorizedSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    closeWitnessedSignModal() {
      this.$root.$emit('bv::hide::modal', 'witnessed-sign-modal', '')
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    closeBeneficiaryNameModal() {
      this.$root.$emit('bv::hide::modal', 'beneficiary-name-modal', '')
      if (!this.beneficiaryNameImage) {
        this.currentBeneficiaryNameType = 'input'
      }
    },
    closeOfficeSectionModal() {
      this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
    },
    saveOfficeSectionData() {
      this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
      const officeRemarksObject = {
        key: 'officeRemarks',
        value: this.officeRemarksVal,
      }
      const stVal = this.serviceTypeValueLocal[0] ? this.serviceTypeValueLocal[0] : ''
      const serviceTypeObject = {
        key: 'serviceType',
        value: stVal,
      }
      if (this.editFormInOtherStatus || this.requestForm.status === 'To prepare') {
        this.officeRemarksChanged = 1
        this.existingOfficeRemarksObject = officeRemarksObject
        this.existingServiceTypeObject = serviceTypeObject
        this.serviceTypeValue = [stVal]
        this.officeRemarks = this.officeRemarksVal
      } else {
        const formData = new FormData()
        formData.append('officeRemarksObject', JSON.stringify(officeRemarksObject))
        formData.append('serviceTypeObject', JSON.stringify(serviceTypeObject))
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/office-section`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
              this.makeFormValues()
              this.$swal({
                title: 'Office Section Data Saved',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    clearWitnessedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveWitnessedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'witnessed-sign-modal', '')
        const witnessedSignObj = {
          key: 'witnessedSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          witnessedSignObj.date = new Date()
          witnessedSignObj.name = this.user.name
          witnessedSignObj.admin = this.user._id
          this.existingWitnessedSignSrcObject = witnessedSignObj
          this.witnessedSignSrc = data
          this.witnessedSign = true
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign && this.authorizedSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(witnessedSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.requestForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    clearBeneficiaryName() {
      this.beneficiaryNameImage = ''
      this.$refs.beneficiarySignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    saveBeneficiaryName() {
      const { data } = this.$refs.beneficiarySignaturePad.saveSignature()
      if (data) {
        this.beneficiaryNameImage = data
        this.$root.$emit('bv::hide::modal', 'beneficiary-name-modal', '')
        this.currentBeneficiaryNameType = 'canvas'
      }
    },
    async handleClick(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].focus()
      }
    },
    async handleClickSelect(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].searchEl.focus()
      }
    },
    async handleClickDateField(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].fp.open()
      }
    },
    async handleClickCleaveField(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        // this.$refs[field].$el.onfocus = true
        // console.log(this.$refs[field])
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.currentCustomerNameType = type
            }
          })
      } else {
        this.currentCustomerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    changeBeneficiaryNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.beneficiaryNameImage = ''
              this.currentBeneficiaryNameType = type
            }
          })
      } else {
        this.currentBeneficiaryNameType = type
        this.$root.$emit('bv::show::modal', 'beneficiary-name-modal', '')
      }
    },
    makeGenderArray() {
      this.genderValue = [this.gender]
    },
    makeCheckboxToRadio() {
      const lastSelected = this.serviceTypeValueLocal.slice(-1)[0]
      if (lastSelected) {
        this.serviceTypeValueLocal = this.serviceTypeValueLocal.filter(code => code === lastSelected)
      }
    },
    submitSaveFormConfirmation() {
      if (this.requestForm.status === 'Signed and Sent') {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitSaveForm()
      }
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
      // this.editFormInOtherStatus = false
      // this.makeFormValues()
    },
    submitSaveForm(option = null, submit = false) {
      this.$refs.serviceRequestFormSave.validate().then(success => {
        let hasCustomerImage = true
        if (this.currentCustomerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        let hasBeneficiaryImage = true
        if (this.currentBeneficiaryNameType === 'canvas' && !this.beneficiaryNameImage) {
          hasBeneficiaryImage = false
          this.beneficiaryNameImageError = true
        }
        if ((option && option === 'saveOnly') || (success && hasCustomerImage && hasBeneficiaryImage)) {
          const formData = new FormData()
          if (option && option === 'readyToSign') {
            formData.append('readyToSign', true)
          }
          const valueArray = []
          let srValueArray = this.serviceRequest.formValues
          const sfValueObject = {}
          const srValueObject = {}
          const customerNameObj = {
            key: 'customerName',
          }
          if (this.currentCustomerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNameObj.key).concat(customerNameObj)

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNRICObj.key).concat(customerNRICObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.currentKinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNameObj.key).concat(kinNameObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== kinNRICObj.key).concat(kinNRICObj)

          srValueObject.fileNo = this.fileNo

          const beneficiaryNameObj = {
            key: 'beneficiaryName',
          }
          if (this.currentBeneficiaryNameType === 'input') {
            beneficiaryNameObj.value = this.beneficiaryName
          } else {
            beneficiaryNameObj.value = this.beneficiaryNameImage
          }
          valueArray.push(beneficiaryNameObj)

          const relationshipObj = {
            key: 'relationship',
            value: this.relationship,
          }
          valueArray.push(relationshipObj)

          const ageObj = {
            key: 'age',
            value: this.age,
          }
          valueArray.push(ageObj)

          const departedOnObj = {
            key: 'departedOn',
            value: this.departedOn,
          }
          valueArray.push(departedOnObj)

          const birthDateObj = {
            key: 'birthDate',
            value: this.birthDate,
          }
          valueArray.push(birthDateObj)

          const religionObj = {
            key: 'religion',
            value: this.religion,
          }
          valueArray.push(religionObj)

          const genderObj = {
            key: 'gender',
            value: this.gender,
          }
          valueArray.push(genderObj)

          const undertakerObj = {
            key: 'undertaker',
            value: this.undertaker,
          }
          valueArray.push(undertakerObj)

          const cremationPlaceObj = {
            key: 'cremationPlace',
            value: this.cremationPlace,
          }
          valueArray.push(cremationPlaceObj)

          const cremationDateObj = {
            key: 'cremationDate',
            value: this.cremationDate,
          }
          valueArray.push(cremationDateObj)

          const serviceAddressObj = {
            key: 'serviceAddress',
            value: this.serviceAddress,
          }
          valueArray.push(serviceAddressObj)

          const contactNoObj = {
            key: 'contactNo',
            value: this.contactNo,
          }
          valueArray.push(contactNoObj)

          const installationDateObj = {
            key: 'installationDate',
            value: this.installationDate,
          }
          valueArray.push(installationDateObj)

          const firstInstallationObj = {
            key: 'firstInstallation',
            value: this.firstInstallation,
          }
          valueArray.push(firstInstallationObj)

          const subsequentInstallationObj = {
            key: 'subsequentInstallation',
            value: this.subsequentInstallation,
          }
          valueArray.push(subsequentInstallationObj)

          const subsequentInstallationFeeObj = {
            key: 'subsequentInstallationFee',
            value: this.subsequentInstallationFee,
          }
          valueArray.push(subsequentInstallationFeeObj)

          const receiptNoObj = {
            key: 'receiptNo',
            value: this.receiptNo,
          }
          valueArray.push(receiptNoObj)

          let witnessSignVal = 0
          let purchaserSignVal = 0
          let authorizedSignVal = 0

          if (this.existingWitnessedSignSrcObject.key) {
            witnessSignVal = 1
            valueArray.push(this.existingWitnessedSignSrcObject)
          }
          if (this.existingAuthorizedSignSrcObject.key) {
            authorizedSignVal = 1
            valueArray.push(this.existingAuthorizedSignSrcObject)
          }
          if (this.existingPurchaserSignObject.key) {
            purchaserSignVal = 1
            valueArray.push(this.existingPurchaserSignObject)
          }
          const serviceTypeObj = {
            key: 'serviceType',
            value: this.serviceType,
          }
          valueArray.push(serviceTypeObj)
          const officeRemarksObj = {
            key: 'officeRemarks',
            value: this.officeRemarks,
          }
          valueArray.push(officeRemarksObj)

          let allSigned = false
          if (witnessSignVal && purchaserSignVal && authorizedSignVal) {
            allSigned = true
          }

          // let valueChanged = false
          // if (this.requestForm.status === 'Signed and Sent') {
          //   const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
          //   if (difference.length) {
          //     const otherValueChanged = difference.find(o => !['officeRemarks', 'serviceType'].includes(o.key))
          //     if (otherValueChanged) {
          //       valueChanged = true
          //     }
          //   }
          // }

          formData.append('values', JSON.stringify(valueArray))
          formData.append('srValues', JSON.stringify(srValueArray))
          formData.append('sfValues', JSON.stringify(sfValueObject))
          formData.append('srValueObject', JSON.stringify(srValueObject))
          formData.append('allSigned', allSigned)
          formData.append('currentStatus', this.requestForm.status)
          formData.append('formName', this.requestForm.formID.name)
          formData.append('serviceRequest', this.serviceRequest._id)
          formData.append('serviceForm', this.serviceRequest.serviceForm._id)
          formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                await this.$emit('update:serviceRequest', response.data.sr)
                this.makeFormValues()
                this.editFormInOtherStatus = false
                if (option && option === 'edited' && response.data.data.status === 'Signed and Sent' && submit) {
                  this.submitSignedForm('edited')
                } else if (response.data.data.status === 'Signing' && allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Form Data Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
